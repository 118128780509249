import { teams, statuses } from "@src/data/task";
export default {
  data() {
    return {
      taskId: this.$route.params.taskId,
      observing: false,
      siteNotFound: false,
      loadingSite: true
    };
  },
  provide() {
    return {
      $taskProvider: () => {
        return {
          isOpen: this.taskIsOpen,
          isQueued: this.taskIsQueued,
          isScheduled: this.taskIsScheduled,
          canBeClosed: this.taskCanBeClosed,
          canBeReopened: this.taskCanBeReopened,
          hasResellerOrigins: this.taskHasResellerOrigins,
          hasResellerClientParticipants: this.taskHasResellerClientParticipants,
          cost: this.taskCost,
          team: this.taskTeam,
          leadAgent: this.taskLeadAgent,
          authorName: this.taskAuthorName,
          authorEmail: this.taskAuthorEmail,
          userIsAgent: this.userIsAgent,
          userIsReseller: this.userIsReseller,
          userIsParticipant: this.userIsParticipant,
          userIsSiteOwner: this.userIsSiteOwner,
          userCanManageParticipants: this.userCanManageParticipants,
          stats: this.task.stats,
          plan: this.task.plan,
          site: this.site,
          siteIsSubscribed: this.siteIsSubscribed,
          siteHasWordpressPlugin: this.siteHasWordpressPlugin,
          siteTaskCredits: this.siteTaskCredits
        };
      },
      $taskMethods: {
        closeTask: this.closeTask,
        reOpenTask: this.reOpenTask,
        updateTaskType: this.updateTaskType,
        updateSubject: this.updateSubject
      }
    };
  },
  computed: {
    loading() {
      return this.$_.isEmpty(this.task);
    },
    user() {
      return this.$store.getters["user/user"]();
    },
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    userIsAgent() {
      return this.$store.getters["user/isAgent"]();
    },
    userIsReseller() {
      return this.$store.getters["user/isReseller"]();
    },
    userIsSiteOwner() {
      return this.$_.get(this.site, "owners", []).includes(this.userId);
    },
    userIsParticipant() {
      return this.$_.get(this.task, "participants", []).includes(this.userId);
    },
    site() {
      return this.$store.getters["sites/site"](this.task.siteId) || {};
    },
    siteIsSubscribed() {
      return this.site.isSubscribed;
    },
    siteHasWordpressPlugin() {
      return this.$_.get(this.site, "wordpress.hasPlugin", false);
    },
    siteTaskCredits() {
      return (
        this.$_.get(this.site, "quota.paidTaskCredits", 0) +
        this.$_.get(this.site, "quota.planTaskCredits", 0)
      );
    },
    userCanManageParticipants() {
      return (
        this.$_.get(this.site, "users", []).length > 1 &&
        (this.userIsSiteOwner || this.userIsAgent)
      );
    },
    task() {
      return this.$store.getters["tasks/task"](this.taskId) || {};
    },
    taskIsOpen() {
      return this.$_.get(this.task, "status", "").startsWith("1-");
    },
    taskIsQueued() {
      return this.$_.get(this.task, "status", "") === statuses.QUEUED;
    },
    taskIsScheduled() {
      return (
        this.task.status === statuses.SCHEDULED && !!this.task.dateScheduled
      );
    },
    taskHasResellerOrigins() {
      return !!this.task.resellerId;
    },
    taskHasResellerClientParticipants() {
      const participants = this.$_.get(this.task, "participantsData", []);
      return !!this.$_.filter(participants, participant => {
        return this.$_.get(participant, "isResellerClient", false);
      }).length;
    },
    taskCanBeClosed() {
      if (!this.taskIsOpen) return false;
      if (this.task.authorId === this.userId) return true;
      if (this.task.participants.includes(this.userId) && !this.userIsAgent)
        return true;
      return false;
    },
    taskCanBeReopened() {
      const sevenDaysAgo = new Date(this.$moment().subtract(7, "days"));
      const dateUpdated = this.task.dateUpdated.toDate();
      return !this.taskIsOpen && dateUpdated > sevenDaysAgo;
    },
    taskAuthorName() {
      return this.$_.get(
        this.task,
        `participantsData[${this.task.authorId}].name`,
        null
      );
    },
    taskAuthorEmail() {
      return this.$_.get(
        this.task,
        `participantsData[${this.task.authorId}].email`,
        null
      );
    },
    taskCost() {
      if (!this.task.creditCost) return "Free";
      let cost = "";
      const planCredits = this.task.consumedPlanCredits;
      const paidCredits = this.task.consumedPaidCredits;
      if (planCredits)
        cost += `${planCredits} plan credit${planCredits > 1 ? "s" : ""}`;
      if (planCredits && paidCredits) cost += `; `;
      if (paidCredits)
        cost += `${paidCredits} paid credit${paidCredits > 1 ? "s" : ""}`;
      return cost.length ? cost : null;
    },
    taskTeam() {
      return this.$_.get(
        this.$_.transform(
          teams,
          (result, value) => (result[value.key] = value.label)
        ),
        this.task.team,
        "--"
      );
    },
    taskLeadAgent() {
      const agentId = this.$_.get(this.task, "alphaAgent.id", null);
      return this.$_.get(this.task, `participantsData.${agentId}.name`, "--");
    }
  },
  created() {
    this.observeTask();
  },
  beforeDestroy() {
    if (this.observing) {
      if (this.task && this.task.siteId && !this.siteNotFound) {
        this.$store.dispatch("sites/unobserveSite", {
          siteId: this.task.siteId
        });
      }
      this.unobserveTask();
    }
  },
  methods: {
    observeTask() {
      this.observing = true;
      return this.$store
        .dispatch("tasks/observeTask", { taskId: this.taskId })
        .then(() => {
          this.$nextTick(() => {
            if (this.task && this.task.siteId) {
              this.loadingSite = true;
              this.$store
                .dispatch("sites/observeSite", {
                  siteId: this.task.siteId
                })
                .catch(() => {
                  this.siteNotFound = true;
                  // Site may be deleted
                })
                .finally(() => {
                  this.loadingSite = false;
                });
            }
          });
        })
        .catch(() => {
          this.taskNotFound();
        });
    },
    unobserveTask() {
      this.$store.dispatch("tasks/binTask", { taskId: this.taskId });
    },
    taskNotFound() {
      const redirect = this.$route.path.startsWith("/admin")
        ? "/admin/tasks"
        : "/tasks";
      this.$router.push(redirect);
      this.$toast.open({
        message: `Task not found`,
        type: "is-danger"
      });
    },
    closeTask() {
      if (this.taskIsOpen) {
        this.$modal.open({
          component: () => import("@shared/tasks/_closeTaskModal"),
          parent: this,
          width: 600,
          hasModalCard: true,
          canCancel: ["outside", "escape"],
          props: {
            task: this.task
          }
        });
      }
    },
    reOpenTask() {
      if (!this.taskIsOpen) {
        return this.$store
          .dispatch("tasks/reopenTask", {
            taskId: this.taskId
          })
          .then(result => {
            this.$toast.open({
              message: result.message
            });
          })
          .catch(error => {
            this.$toast.open({
              message: error.message,
              type: "is-danger"
            });
          });
      }
    },
    updateTaskType() {
      if (this.userIsAgent) {
        this.$modal.open({
          component: () => import("@shared/tasks/_updateTaskTypeModal"),
          parent: this,
          width: 540,
          hasModalCard: true,
          canCancel: ["outside", "escape"],
          props: {
            taskId: this.taskId,
            taskTypeRef: this.task.taskTypeRef
          }
        });
      }
    },
    updateSubject() {
      this.$modal.open({
        component: () => import("@shared/tasks/_updateTaskSubjectModal"),
        parent: this,
        width: 540,
        hasModalCard: true,
        canCancel: ["outside", "escape"],
        props: {
          taskId: this.taskId
        }
      });
    }
  }
};
